<template>
    <div>
        <v-card class="rounded-xxl pb-4 elevation-5"  min-height="600" color="#fff">
            <v-card-text>
                <v-data-table
                    dense
                    :headers="headers"
                    :items="itemLists"
                    class="elevation-1 mb-5"
                    :items-per-page="30"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    :loading="$store.state.overlay"
                    height="440"
                    fixed-header
                    :divider="true"
                    :light="true"
                    :search="searchItem" 
                    :item-class="tr_datatable"
                >    
                    <template v-slot:[`header.aprv`]="{ header }">
                      APPROVE {{ aprv }}
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat color="white" class="mt-2 mb-2">
                            <div class="d-flex w-100">
                                <v-spacer></v-spacer>
                                <v-text-field solo style="max-width: 300px;" class=" mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                <v-btn
                                small
                                color="#fff"
                                class="py-5 mr-3 hidden-sm-and-down "
                                @click="populateData()"
                                >
                                    <v-icon>mdi-cached</v-icon>
                                </v-btn>
                            </div>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.file_name`]="{ item }">
                        <v-chip v-if="item.file_name == 'Y'" @click="showImage(item)" color="green" small dark>view</v-chip>
                    </template>
                    <template v-slot:[`item.stat`]="{ item }">
                        <v-chip class="ma-2" v-if="item.stat == 'O'" color="blue" dense small dark>OPEN</v-chip>
                        <v-chip class="ma-2" v-else-if="item.stat == 'C'" color="red" dense small dark>CLOSED</v-chip>
                    </template>
                    <template v-slot:[`item.aprv`]="{ item }">
                      <div v-if="aprv === 1 || aprv === '1'">
                        <div v-if="item.dt_reject != '01-01-1900'">
                            <v-chip class="ma-2"  color="red" small label outlined>Reject</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv == '01-01-1900' && item.dt_aprv_2 == '01-01-1900' && item.dt_aprv_3 == '01-01-1900' && (item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == '' || item.dt_aprv_4 == null)">
                            <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Approve <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                        </div>
                        <div v-else-if="item.dt_aprv == '01-01-1900'">
                            <v-chip class="ma-2" small  color="warning" label outlined>Not Approved</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv != '01-01-1900'">
                            <v-chip class="ma-2" small color="success" label outlined>Approved {{ item.dt_aprv }}</v-chip>
                        </div>
                      </div>
                      <div v-else-if="aprv === 2 || aprv === '2'">
                        <div v-if="item.dt_reject != '01-01-1900'">
                            <v-chip class="ma-2"  color="red" small label outlined>Reject</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv != '01-01-1900' && item.dt_aprv_2 == '01-01-1900' && item.dt_aprv_3 == '01-01-1900' && (item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == '' || item.dt_aprv_4 == null)">
                            <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Approve <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                        </div>
                        <div v-else-if="item.dt_aprv_2 == '01-01-1900'">
                            <v-chip class="ma-2" small  color="warning" label outlined>Not Approved</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv_2 != '01-01-1900'">
                            <v-chip class="ma-2" small color="success" label outlined>Approved {{ item.dt_aprv_2 }}</v-chip>
                        </div>
                      </div>
                      <div v-else-if="aprv === 3 || aprv === '3'">
                        <div v-if="item.dt_reject != '01-01-1900'">
                            <v-chip class="ma-2"  color="red" small label outlined>Reject</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv != '01-01-1900' && item.dt_aprv_2 != '01-01-1900' && item.dt_aprv_3 == '01-01-1900' && (item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == '' || item.dt_aprv_4 == null)">
                            <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Approve <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                        </div>
                        <div v-else-if="item.dt_aprv_3 == '01-01-1900'">
                            <v-chip class="ma-2" small  color="warning" label outlined>Not Approved</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv_3 != '01-01-1900'">
                            <v-chip class="ma-2" small color="success" label outlined>Approved {{ item.dt_aprv_3 }}</v-chip>
                        </div>
                      </div>
                      <div v-else-if="aprv === 4 || aprv === '4'">
                        <div v-if="item.dt_reject != '01-01-1900'">
                            <v-chip class="ma-2"  color="red" small label outlined>Reject</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv != '01-01-1900' && item.dt_aprv_2 != '01-01-1900' && item.dt_aprv_3 != '01-01-1900' && (item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == '' || item.dt_aprv_4 == null)">
                            <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Approve <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                        </div>
                        <div v-else-if="item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == '' || item.dt_aprv_4 == null">
                            <v-chip class="ma-2" small  color="warning" label outlined>Not Approved</v-chip>
                        </div>
                        <div v-else-if="item.dt_aprv_4 != '01-01-1900'">
                            <v-chip class="ma-2" small color="success" label outlined>Approved {{ item.dt_aprv_4 }}</v-chip>
                        </div>
                      </div>
                    </template>
                    <!-- <template v-slot:[`item.aprv2`]="{ item }">
                      <div v-if="item.dt_reject != '01-01-1900'">
                          <v-chip class="ma-2"  color="red" label outlined>Ditolak</v-chip>
                      </div>
                      <div v-else-if="aprv_num == 2 && item.dt_aprv != '01-01-1900' && item.dt_aprv_2 == '01-01-1900' && item.dt_aprv_3 == '01-01-1900'">
                          <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Setujui <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                      </div>
                      <div v-else-if="item.dt_aprv_2 == '01-01-1900'">
                          <v-chip class="ma-2" small  color="warning" label outlined>Belum Disetujui</v-chip>
                      </div>
                      <div v-else-if="item.dt_aprv_2 != '01-01-1900'">
                          <v-chip class="ma-2" small color="success" label outlined>Disetujui {{ item.dt_aprv_2 }}</v-chip>
                      </div>
                    </template>
                    <template v-slot:[`item.aprv3`]="{ item }">
                      <div v-if="item.dt_reject != '01-01-1900'">
                          <v-chip class="ma-2"  color="red" label outlined>Ditolak</v-chip>
                      </div>
                      <div v-else-if="aprv_num == 3 && item.dt_aprv != '01-01-1900' && item.dt_aprv_2 != '01-01-1900' && item.dt_aprv_3 == '01-01-1900'">
                          <v-btn class="ma-2 text--white" small color="success" elevation="2" @click="showAproveAbsence(item)">Setujui <v-icon class="ml-3" color="#fff">mdi-check</v-icon></v-btn>
                      </div>
                      <div v-else-if="item.dt_aprv_3 == '01-01-1900'">
                          <v-chip class="ma-2" small  color="warning" label outlined>Belum Disetujui</v-chip>
                      </div>
                      <div v-else-if="item.dt_aprv_3 != '01-01-1900'">
                          <v-chip class="ma-2" small color="success" label outlined>Disetujui {{ item.dt_aprv_3 }}</v-chip>
                      </div>
                    </template> -->
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogShowImage"
            persistent
            max-width="800px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    List File
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialogShowImage = false]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-carousel cycle height="100%">
                    <v-carousel-item
                        v-for="(item,i) in imageList"
                        :key="i"
                        :src="item"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    ></v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_aprv"
            persistent
            max-width="600px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    Approval
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialog_aprv = false]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-container class="fill-height">
                    <v-row align="center" justify="center">
                        <v-col cols="12" xs="12" sm="2" md="2">
                            <v-icon x-large color="success" class="text-center">
                                mdi-check
                            </v-icon>
                        </v-col>
                    </v-row> 
                    <v-row align="center" justify="center">
                        <v-col cols="12" xs="12" sm="8" md="10">
                            <h6 class="text-center">
                            Are you sure approve this Absence?
                            </h6>
                            <p class="text-center">if yes, please click Approve. if no, please click Reject</p>
                        </v-col>
                    </v-row> 
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="10" md="10">
                        <v-btn class="border-12 mt-0 mr-3" style="padding: 19px;" small color="error" elevation="2" @click="rejected()">Reject <v-icon class="ml-3">mdi-close</v-icon></v-btn>
                        <v-btn class="border-12 mt-0" style="padding: 19px;" small color="success" elevation="2" @click="approved()">Approve <v-icon class="ml-3">mdi-check</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  import { mapState } from 'vuex'
  import {user} from "@/backend-api/user"
  import {appl_constant} from "@/backend-api/appl_constant"
  import {employee} from "@/backend-api/employee"
  import {department} from "@/backend-api/department"
  import {divisi} from "@/backend-api/divisi"
  import {section} from "@/backend-api/section"
  import {group_section} from "@/backend-api/group_section"
  
  export default {
    props: ['aprv', 'itemLists'],
    data: () => ({
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/employee',
            },
            {
                text: 'Approval Absence',
                disabled: true,
            }
        ],
        headers: [
            { text: 'TRANS DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'ID EMPLOYEE', value: 'idemployee', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'EMPLOYEE', value: 'employee', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'REASON', value: 'ijin_description', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'ATTACHMENT', value: 'file_name', align: 'center', class: 'primary--text blue lighten-5' },
            { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'START DATE', value: 'dt_from', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'UNTIL DATE', value: 'dt_to', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'INTERVAL', value: 'interval', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'APRV', value: 'aprv', align: 'left', class: 'primary--text blue lighten-5' },
            // { text: 'APRV 2', value: 'aprv2', align: 'left', class: 'primary--text blue lighten-5' },
            // { text: 'APRV 3', value: 'aprv3', align: 'left', class: 'primary--text blue lighten-5' },
        ],
        headersColumn:{
        'DATE': 'tr_date',
        'REASON': 'ijin_descr',
        'REMARK': 'keterangan',
        'START DATE': 'dt_from',
        'UNTIL DATE': 'dt_to',
        'Stat': 'stat',
        },
        // itemLists: [],
        searchItem: '',
        addCompanyIDModel: '',
        addCompanyIDList: [],
        addIjinModel: '',
        addIjinList: [],
        addIjinLoading: false,
        dateStartModal: false,
        dateStartModel: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateStartFormatted: '',
        dateEndModal: false,
        dateEndModel: '',
        dateEndFormatted: '',
        departments: [],
        department: '',
        divisi: '',
        divisions: [],
        section: '',
        sections: [],
        group_section: '',
        group_sections: [],
        employees: [],
        employee: '',
        max_aprv: 1,
        tr_types: [],
        tr_type: 'IMP',
        aprv_num: 0,
        detailItem: null,
        dialogShowImage: false,
        imageList: [],
        dialog_aprv:false,
        disabled_divisi: false,
        disabled_section: false,
        disabled_sec_group: false,
        tabs: [],
        tab:''
    }),
    async mounted() {
        console.log(this.aprv);
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
          if(this.disabled_divisi == false && this.disabled_section == false && this.disabled_sec_group == false){
            this.divisi = ''
            this.section = ''
            this.group_section = ''
            this.sections = []
            this.group_sections = []
          } else if (this.disabled_divisi == true && this.disabled_section == false && this.disabled_sec_group == false){
            this.section = ''
            this.group_section = ''
            this.group_sections = []
          } else if (this.disabled_divisi == true && this.disabled_section == true && this.disabled_sec_group == false){
            this.group_section = ''
            this.group_sections = []
          }
          this.dateStartModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.dateEndModel = ''
        },
        async initialize(){
            const respData = await user.fetchCompany(`?id=${this.$store.state.user.id}`, null, false, false, false) 
            if (respData.status === 200) {
                this.addCompanyIDModel = this.$store.state.user.employee.company_id
                this.addCompanyIDList = respData.data.entity
                this.addIjinList = respData.data.ijin
            }
        },
        async getApplConstant(){
          var reqBody = {
            key_id: 'TR_TYPE',
            // str3: this.$store.state.user.employee.company_id
          }

          const respData = await appl_constant.index('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.tr_types = respData.data
          }
        },
        async getDepartment(){
          var reqBody = {
            company_id: this.$store.state.user.employee.company_id,
            idemployee:  this.$store.state.user.id
          }

          const respData = await department.deptViewAprv('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.department = respData.data.length > 0 ? respData.data[0].dep_id : ''
            this.departments = respData.data
            await this.maxApprove(this.department)
            await this.getDivisi(this.department)
            await this.getEmployee(this.department)
          }
        },
        async getEmployee(event){
          if (event == '' || event == null) {
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id,
              dept_id:  this.department ? this.department : '',
              division_id:  this.divisi ? this.divisi : '',
              section_id:  this.section ? this.section : '',
              group_id:  this.group_section ? this.group_section : ''
            }

            const respData = await employee.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.employees = respData.data
            }
          }
        },
        async maxApprove(event){
          if (event == '' || event == null) {
            this.max_aprv = 1
          } else {
            
            var reqBody = {
              company: this.$store.state.user.employee.company_id.trim(),
              tr_type: this.tr_type,
              dep_id: this.department ? this.department.trim() : ''
            }

            await this.checkUserAprvNum()
          }
          
        },
        async populateData(){
          this.$emit("displayData", this.aprv);
        },
        async checkUserAprvNum(){
          var reqBody = {
            company: this.$store.state.user.employee.company_id.trim(),
            tr_type: this.tr_type,
            dep_id: this.department ? this.department.trim() : '',
            idemployee:  this.$store.state.user.id
          }

          const respData = await user.cekUserApproveNums('', reqBody, false, false, false)

          if (respData.status === 200) {
            this.tabs = respData.data
            // this.aprv_num = respData.data.aprv_num ? Number(respData.data.aprv_num) : 0
            // if (respData.data.aprv_level == 'DEPARTMENT') {
            //   this.disabled_divisi = false
            //   this.disabled_section = false
            //   this.disabled_sec_group = false
            // } else if(respData.data.aprv_level == 'DIVISION'){
            //   this.divisi = respData.data.div_id
            //   this.disabled_divisi = true
            //   this.disabled_section = false
            //   this.disabled_sec_group = false
            // } else if(respData.data.aprv_level == 'SECTION'){
            //   this.section = respData.data.sec_id
            //   this.disabled_divisi = true
            //   this.disabled_section = true
            //   this.disabled_sec_group = false
            //   await this.getSection(this.section)
            // } else if(respData.data.aprv_level == 'GROUPSECTION'){
            //   this.group_section = respData.data.gsc_id
            //   this.disabled_divisi = true
            //   this.disabled_section = true
            //   this.disabled_sec_group = true
            //   await this.getGroupSection(this.group_section)
            // }
          }
        },
        async getDivisi(event){
          if (event == '' || event == null) {
            this.divisi = ''
            this.section = ''
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : ''
            }

            const respData = await divisi.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.divisions = respData.data
            }
          }
        },
        async getSection(event){
          if (event == '' || event == null) {
            this.section = ''
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : '',
              section_id: this.section ? this.section : ''
            }

            const respData = await section.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.sections = respData.data
            }
          }
        },
        async getGroupSection(event){
          if (event == '' || event == null) {
            this.group_section = ''
            this.employee = ''
          } else {
            var reqBody = {
              entity_id: this.$store.state.user.employee.company_id.trim(),
              dept_id: this.department ? this.department.trim() : '',
              division_id: this.divisi ? this.divisi : '',
              section_id: this.section ? this.section : ''
            }

            const respData = await group_section.index('', reqBody, false, false, false)

            if (respData.status === 200) {
              this.group_sections = respData.data
            }
          }
        },
        async showImage(item){
          this.$store.dispatch('setOverlay', true)
          this.imageList = []

          var reqBody = {
            'id': item.doc_id,
            'idemployee': item.idemployee,
            'company_id': this.$store.state.user.employee.company_id
          }

          const respData = await user.getImages('', reqBody, false, false, false)

          if (respData.status === 200) {
            // console.log(respData);
            this.dialogShowImage = true

            for (let index = 0; index < respData.data.length; index++) {
                const element = respData.data[index];
                this.imageList.push(process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + element.file_name)
            }
            this.$store.dispatch('setOverlay', false)

          }
        },
        showAproveAbsence(item){
          this.detailItem = item
          this.dialog_aprv = true
        },
        async approved(){
          this.dialog_aprv = false
          var reqBody = {
            company_id: this.detailItem ? this.detailItem.company_id : '',
            tr_type: this.detailItem ? this.detailItem.tr_type : '',
            doc_id: this.detailItem ? this.detailItem.doc_id : '',
            idemployee: this.detailItem ? this.detailItem.idemployee : '',
            id_aprv: this.$store.state.user.id,
            aprv_num: Number(this.aprv)
          }
          this.$store.dispatch('setOverlay', true)

          const respData = await user.approve('', reqBody, false, false, false)

          if (respData.status === 200) {
            Swal.fire({
                text: 'Success Approve Absence',
                icon: 'success',
            })
            this.$emit("displayData", this.aprv);
            // this.populateData()
          }else {
            console.log(respData.data);
          }

          this.$store.dispatch('setOverlay', false)

        },
        async rejected(){
          this.dialog_aprv = false
          var reqBody = {
            company_id: this.detailItem ? this.detailItem.company_id : '',
            tr_type: this.detailItem ? this.detailItem.tr_type : '',
            doc_id: this.detailItem ? this.detailItem.doc_id : '',
            idemployee: this.detailItem ? this.detailItem.idemployee : '',
            id_aprv: this.$store.state.user.id,
            aprv_num: Number(this.aprv)
          }

          this.$store.dispatch('setOverlay', true)

          const respData = await user.reject('', reqBody, false, false, false)

          if (respData.status === 200) {
            Swal.fire({
                text: 'Success Reject Absence',
                icon: 'success',
            })
            // this.populateData()
            this.$emit("displayData", this.aprv);
          } else {
            console.log(respData.data);
          }

          this.$store.dispatch('setOverlay', false)
        }
    },
    watch: {
    }
  }
  </script>
    
  <style scoped>
  .tr_datatable{
    background-color: #F5F7F8 !important;
  }
  </style>