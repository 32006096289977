import {backendApi} from "./backend-api"

export const department = (() =>{
    const test = (() => {
        return ""
    })

    const deptViewAprv = ( async (subPath, reqBody) => {
        var url = '/v2/hris/deptViewAprv'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const deptViewAprvPTK = ( async (subPath, reqBody) => {
        var url = '/v2/hris/deptViewAprvPTK'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })


    return {test, deptViewAprv, deptViewAprvPTK};

})()